@font-face {
  font-family: 'RocaTwo';
  src: url('../assets/fonts/RocaTwo-600.woff2');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Posterama';
  src: url('../assets/fonts/PosteramaText-400.woff2');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Posterama';
  src: url('../assets/fonts/PosteramaText-600.woff2');
  font-weight: 600;
  font-display: swap;
}

a {
  text-decoration: none;
}
