button {
  background-color: transparent;
  border: none;
  outline: none;
  color: inherit;
  text-align: left;
  cursor: pointer;

  &:focus-visible {
    outline: 2px solid #e86844;
    border-radius: 4px;
  }
}
