:global(.ac-swiper-pagination) {
  display: flex;
  gap: 6px !important;
  align-items: center;
  flex-direction: row !important;
}

:global(.ac-swiper-pagination__bullet) {
  background: #82AD99;
  height: 3px;
  width: 15px;
  border-radius: 20rem;
  cursor: pointer;
}

:global(.ac-swiper-pagination__bullet--active) {
  background: #285847;
}
