.ac-attendance-swiper {
  .swiper {
    .swiper-wrapper {
      @media screen and (min-width: 768px) {
        .swiper-slide {
          opacity: 0;
          transform: scale(0.8);
          transform-origin: center;
          transition: all 500ms ease;
          &.swiper-slide-active {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
    }
  }
}
